.header-container {
  background-color: $primary;
  background-image: url("../../images/overlay-header.png");
  background-repeat: no-repeat;
  background-position: 0 0;
}

.footer-container {
  background-color: $primary;
  background-image: url("../../images/overlay-footer.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.page-section#long-term-goals {
  position: relative;
  &:before, &:after {
    position: absolute;
    z-index: 1;
  }
  &:before {
    content: url("../../images/overlay-body-left.png");
    left: 0;
    top: 50%;
  }
  &:after {
    content: url("../../images/overlay-body-right.png");
    right: 0;
    bottom: 50%;
  }
}


.page-section#who-we-are {
  position: relative;
  &:before, &:after {
    position: absolute;
    z-index: 1;
  }
  &:before {
    content: url("../../images/overlay-people-left.png");
    left: 0;
    top: 20%;
  }
}

@include media-breakpoint-down(lg) {

  .page-section#long-term-goals {
    &:before {
      zoom: 50%;
    }
  }
}

@include media-breakpoint-down(md) {
  .header-container, .footer-container {
    background-size: contain;
  }

  .page-section#long-term-goals {
    &:before {
      zoom: 30%;
      top: 85%;
      display: none;
    }
    &:after {
      zoom: 50%;
      bottom: 80%;
      display: none;
    }
  }

  .page-section#who-we-are {
    &:before {
      zoom: 30%;
      top: 85%;
      display: none;
    }
  }
}
