@for $i from 0 through 1000 {
  .top-#{$i} {
    top: #{$i}#{'%'};
  }

  .right-#{$i} {
    right: #{$i}#{'%'};
  }

  .left-#{$i} {
    left: #{$i}#{'%'};
  }

  .bottom-#{$i} {
    bottom: #{$i}#{'%'};
  }

  .b-#{$i} {
    border: #{$i}px solid;
  }

  .vw#{$i} {
    font-size: #{$i}vw;

    @include media-tablets {
      font-size: #{$i*2}vw;
    }

    @include media-phones-landscape {
      font-size: #{$i*2}vw;
    }
  }

  .w-#{$i} {
    width: #{$i}#{'%'} !important;
  }

  .h-#{$i} {
    height: #{$i}#{'%'} !important;
  }


  .vw-#{$i} {
    width: #{$i}vw !important;
  }

  .vh-#{$i} {
    height: #{$i}vh !important;
  }

  .px#{$i} {
    font-size: #{$i}px;

    @include media-tablets {
      font-size: #{$i*2}px;
    }

    @include media-phones-landscape {
      font-size: #{$i*2}px;
    }
  }

  .o#{$i} {
    opacity: #{$i/10};
  }

  .bo#{$i} {
    background-opacity: #{$i/10};
  }
}

.animate-sideBounce {
  animation: sideBounce 0.5s infinite alternate;
  -webkit-animation: sideBounce 0.5s infinite alternate;
}

.animate-bounce {
  animation: bounce 0.5s infinite alternate;
  -webkit-animation: bounce 0.5s infinite alternate;
}

.mobile-landscape-only {
  display: none;

  @include media-phones-landscape {
    display: initial;
  }
}

.col-md-0 {
  flex-basis: 0% !important;
  width: 0 !important;
  max-width: 0 !important;
}

.half-container {
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 576px) {
      max-width: 270px;
    }
    @media (min-width: 769px) {
      max-width: 360px;
    }
    @media (min-width: 992px) {
      max-width: 480px;
    }
    @media (min-width: 1200px) {
      max-width: 570px;
    }

  &-left {
      margin-right: 0;
  }
  &-right {
      margin-left: 0;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100% !important;
    margin: 0 !important;
  }
}


.text-bold {
  font-weight: bold;
}