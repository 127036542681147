.multiselect__tags {
  display: flex !important;
  padding: 0 !important;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100% !important;
  border: 1px solid #808080 !important;
  border-radius: 0 !important;

  .multiselect__tags-wrap {
    display: inline-flex;
    padding-left: 10px;

    span.multiselect__tag {
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  span.multiselect__single, span.multiselect__placeholder {
    margin: 0 !important;
    width: auto !important;
    padding: 0 0 0 10px !important;
    text-transform: uppercase;
    /* Text style for "EMAIL" */
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 2.65px;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;
  }

  span.multiselect__placeholder {
    color: $grey-light-mid;
  }

  span.multiselect__single {
    color: #4d4d4d;
  }
}

span.multiselect__option {
  text-transform: uppercase;
  font-size: 17px;
  letter-spacing: 2.65px;
}

.multiselect__select::before {
  border-color: $green-mid transparent transparent !important;
}

.multiselect__tag {
  background: $green-mid;
}

.navbar {
  z-index: $navbar-zindex;

  &-brand {
    img {
      width: 25vw;
    }
  }
}