$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 769px,
        lg: 992px,
        xl: 1200px
) !default;

@mixin background($color, $opacity: 1, $hover: true, $important: false) {
  @if ($important) {
    background-color: rgba($color, $opacity) !important;
  } @else {
    background-color: rgba($color, $opacity);
  }

  @if ($hover) {
    @if ($important) {
      &.bg-hover:hover {
        background-color: lighten(rgba($color, $opacity), 10%) !important;
      }
    } @else {
      &.bg-hover:hover {
        background-color: lighten(rgba($color, $opacity), 10%);
      }
    }
  }
}

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

@mixin content_section($background, $color) {
  background: $background;
  p {
    font-size: 2rem;
    color: $color;
    text-align: left;

    &.bar {
      &:before {
        content: '';
        display: block;
        margin: 1.5vw 0;
        border-top: 1vw solid $color;
        width: 100%;
      }
    }
  }

  &.no-image {
    h1 {
      &:before {
        margin: 0 0 1.5rem 0;
      }
    }

    p.bar {
      &:before {
        margin: 0 0 2rem 0;
      }
    }
  }

  h1 {
    text-align: left;
    font-weight: 500;
    font-size: 4vw;
    color: $color;
    position: relative;
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      border-top: 0.75rem solid $color;
      width: 100%;
    }

    span {
      display: inline-block;
      width: 85%;
    }
  }
}

@mixin background($color, $opacity: 1, $important: false) {
  @if ($important) {
    background-color: rgba($color, $opacity) !important;
  } @else {
    background-color: rgba($color, $opacity);
  }
}

@mixin media-tablets() {
  @media (max-width: 769px) and (orientation: portrait) {
    @content;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px) and (orientation: portrait)  {
    @content;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    @content;
  }

  @media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    @content;
  }
}


@mixin media-tablets-landscape() {
  @media (max-width: 769px) and (orientation: landscape) {
    @content;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px) and (orientation: landscape)  {
    @content;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }

  @media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin media-phones() {
  @media only screen
  and (min-device-width : 414px)
  and (max-device-width : 736px)
  and (orientation: portrait) {
    @content;
  }

  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 667px)
  and (orientation: portrait) {
    @content;
  }

  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 812px)
  and (-webkit-device-pixel-ratio : 3)
  and (orientation: portrait) {
    @content;
  }

  @media only screen
  and (max-device-width: 576px)
  and (orientation: portrait) {
    @content;
  }
}

@mixin media-phones-landscape() {
  @media only screen
  and (min-device-width : 414px)
  and (max-device-width : 736px)
  and (orientation: landscape) {
    @content;
  }

  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 667px)
  and (orientation: landscape) {
    @content;
  }

  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 812px)
  and (-webkit-device-pixel-ratio : 3)
  and (orientation: landscape) {
    @content;
  }

  @media only screen
  and (max-device-width: 576px)
  and (orientation: landscape) {
    @content;
  }
}

@mixin media-ie() {
  @supports (-ms-overflow-style: none) {
    @content;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

