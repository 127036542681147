.blowout {
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-transform: translateY(-10vh);
  -moz-transform: translateY(-10vh);
  -ms-transform: translateY(-10vh);
  -o-transform: translateY(-10vh);
  transform: translateY(-10vh);

  @include media-breakpoint-down(lg) {
    padding: 25px 0;
  }
  
  @include media-breakpoint-down(md) {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    margin: 25px 15px !important; //bs override
    width: calc(100% - 30px) !important; //bs override
  }

  .additional-content {
    padding: 0 3%;

    @include media-breakpoint-down(lg) {
      padding: 0 2%;
    }

    &--top {
      margin-bottom: 35px;
    }

    &--bottom {
      margin-top: 35px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      margin: 0;

      i {
        margin-right: 10px;
      }
    }
  }

  .blowout-column-inner {
    padding-left: 10%;
    padding-right: 10%;

    @include media-breakpoint-down(lg) {
      padding-left: 6%;
      padding-right: 6%;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    a {
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;

      &.btn {
        font-weight: 700;
        font-size: 16px;
        justify-content: center;
        display: inline-flex;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .additional-content {
      h3 {
        font-size: 20px !important;
      }
    }

    .blowout-column-inner {
      margin-bottom: 2rem !important;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 16px !important;
      }
    }
  }


}
