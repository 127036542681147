$colours: (
        'white': #ffffff,
        'black': #000000,
        'transparent': transparent,
        'blueMid': $blue-mid,
        'blueDark': $blue-dark,
        'greenMid':$green-mid,
        'greenDark': $green-dark,
        'greenLight': $green-light,
        'orangeLight': $orange-light,
        'orangeMid': $orange-mid,
        'orangeMidDark': $orange-mid-dark,
        'orangeDark': $orange-dark,
        'greyLightest': $grey-lightest,
        'greyLight': $grey-light,
        'greyLightMid': $grey-light-mid,
        'greyMid': $grey-mid,
        'greyMidAlt': $grey-mid-alt,
        'greyMidDark': $grey-mid-dark,
        'greyDark': $grey-dark,
        'tanDark': $tan-dark,
        'pinkDark': $pink-dark,
        'purpleMain': $purple-main,
        'purpleDark': $purple-dark
);

@each $colourName, $colour in $colours {
  .stroke-#{$colourName} {
    svg {
      path {
        stroke: $colour;
      }
      stroke: $colour;
    }
    stroke: $colour;
  }
  .stroke-darken-#{$colourName} {
    svg {
      path {
        stroke: darken($colour, 10%);
      }
    }
  }
  .stroke-lighten-#{$colourName} {
    svg {
      path {
        stroke: lighten($colour, 10%);
      }
    }
  }
  .bg-#{$colourName} {
    @include background($colour);

    &.btn-primary {
      @include background($colour);
    }

    &.btn-rounded {
      &:hover {
        @include background(darken($colour, 10%), 1, true);
      }
    }
  }
  .bg-#{$colourName}-important { @include background($colour, 1, true); }
  .bg-darken-#{$colourName} { color: darken($colour, 10%) }
  .bg-darken-after-#{$colourName} {
    &:after {
      color: darken($colour, 10%)
    }
  }
  .bg-lighten-#{$colourName} { color: lighten($colour, 10%) }
  .bg-lighten-after-#{$colourName} {
    &:after {
      color: lighten($colour, 10%)
    }
  }
  .outline-#{$colourName} { border: 1px solid $colour; }
  .overlay-#{$colourName} { @include background($colour, 0.5, false); }
  .fill-#{$colourName} { fill: $colour; }
  .border-#{$colourName} { border-top: 1px solid $colour; }
  .border-bottom-#{$colourName} { border-bottom: 1px solid $colour; }
  .border-after-#{$colourName} {
    &:before {
      border-top: 0.6vw solid #{$colour};

      @include media-tablets {
        border-top: 1.8vw solid #{$colour};
      }

      @include media-phones-landscape {
        border-top: 1.8vh solid #{$colour};
      }
    }
  }
  .border-after-small-#{$colourName} {
    &:before {
      border-top: 0.3vw solid #{$colour};
    }
  }

  .#{$colourName} { color: $colour !important; }
  .same-hover-#{$colourName} {
    color: $colour;
    &:hover {
      color: $colour !important;
    }
  }
  .link-hover-#{$colourName} {
    position: relative;

    &:hover {
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $colour;
      }
    }
  }
  .child-links-#{$colourName} {
    a {
      color: $colour !important;
      position: relative;

      &:after {

        content: "\f060";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        transition: 1s;
        position: absolute;
        animation: sideBounce 0.5s infinite alternate;
        -webkit-animation: sideBounce 0.5s infinite alternate;
        height: 100%;
        font-size: 100%;
        line-height: 1;
        top: -20%;

        @include media-tablets {
          height: auto !important;
          top: -5%;
        }
        @include media-phones-landscape {
          height: auto !important;
          top: -5%;
        }
      }

      &.ms {
        &:after {
          top: 5%;
        }
      }

      &:hover {
        color: darken($colour, 10%) !important;

        &:after {
          color: darken($colour, 10%) !important;
        }
      }
    }
  }
  .after-#{$colourName} {
    &:after {
      color: $colour !important;
    }
  }
  .before-#{$colourName} {
    &:before {
      color: $colour !important;
    }
  }
  .darken-#{$colourName} { color: darken($colour, 10%) }
  .darken-after-#{$colourName} {
    &:after {
      color: darken($colour, 10%)
    }
  }
  .darken-before-#{$colourName} {
    &:before {
      color: darken($colour, 10%)
    }
  }
  .lighten-#{$colourName} { color: lighten($colour, 10%) }
  .lighten-after-#{$colourName} {
    &:after {
      color: lighten($colour, 10%)
    }
  }
  .lighten-before-#{$colourName} {
    &:before {
      color: lighten($colour, 10%)
    }
  }

  @for $i from 0 through 10 {
    @if ($i == 0) {
      .bg-#{$colourName}-o0 {
        @include background($colour, 0);
      }
    } @else {
      .bg-#{$colourName}-o#{$i} {
        @include background($colour, $i/10);
      }
    }
  }
}