.page-hero {
  display: flex;
  align-items: center;
  padding-top: 40px;
  min-height: 20vh;

  &.has-subsection {
    margin-bottom: 3rem;
    position: relative;
    top: 3rem;

    .hero-image {
      padding-left: 0;
    }
  }

  .hero-content {
    margin-top: -5vh;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }

    h2 {
      font-weight: bold;
      color: #fffefe;
      margin-right: 10%;
      margin-bottom: 50px;
    }

    &.has-subsection {
      align-self: flex-end;
      padding-right: 0;
    }
  }
  .hero-subsection {
    padding: 3rem;
    background-color: $tan-dark;

    strong {
      font-weight: bold;
    }
  }

  .hero-image {
    width: 50%;
    text-align: right;
    padding-right: 0;
    padding-left: 5%;
    img {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    padding-top: 15vh;
    margin-bottom: 15vh;

    .hero-content {
      h2 {
        line-height: 1.5;
        font-size: 6.75vw !important;
      }
    }

    .hero-image {
      margin-top: 15vh;
      width: 85%;
      margin-left: 15%;
      padding-left: 0;
      margin-bottom: -10vh;
    }
  }

  position: relative;

  .overlay-header {
    img {
      width: auto;
    }
  }
}
