.columns-section {
  @include media-breakpoint-down(md) {
    height: auto !important;
  }

  .column-row {
    width: 100%; //ie11 fix for d-flex parent
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
    }

    .column-image {
      @media screen and (min-aspect-ratio: 2000/1000) and (max-aspect-ratio: 2399/1000) and (max-width: 1441px) {
        max-width: 80%;
      }

      @media screen and (min-aspect-ratio: 2400/1000) and (max-width: 1441px) {
        max-width: 60%;
      }

      width: 100%;
      height: 100%;
      align-self: center;
    }

    h1, h2, h3, h4, h5, h6, p {
      margin-bottom: 25px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 5vh;
      margin-bottom: 5vh;
      h1, h2, h3, h4, h5, h6 {
        line-height: 1.5;
      }
    }
  }

  &#long-term-goals {
    .column {
      &:last-of-type {
        font-weight: bold;
        margin-top: 50vh;
        color: #fff;
        font-size: 32px;
        padding: 7vh;
      }
    }

    @include media-breakpoint-down(md) {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 30%;
      position: relative;
      height: 100vh !important;

      .column {
        &:last-of-type {
          padding: 5vh 20px;
          position: absolute;
          bottom: -75px;
          left: 20px;
          right: 20px;
          width: calc(100% - 40px);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &#access-portal {
    .column {
      &:last-of-type {
        ul {
          list-style: none;
          padding-left: 0;
          li {
            color: #fff;
            position: relative;
            padding-left: 20px;
            &:before {
              position: absolute;
              content: "\f00c";
              font-family: 'FontAwesome';
              left: 0;
            }
          }
        }
      }
    }
  }

  &#intro {
    .column-row {
      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
  }
}
