.icon-grid-section {
  .icon-grid-row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .icon-grid-item {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
      padding-left: 3%;
      padding-right: 3%;

      img {
        margin-bottom: 20px;
      }
      h6 {
        line-height: 1;
        margin-bottom: 20px;
      }
    }

    @include media-breakpoint-down(md) {
      .icon-grid-item {
        width: 100% !important;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
}
