.position-block {
  margin-top: 10vh;
  margin-bottom: -30vh;

  .position-blurb {
    /* Style for "There are" */
    color: $grey-dark;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 28px;
  }

  .position-row {
    margin-bottom: 15px;

    .position-info {
      h3 {
        color: $purple-dark;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin: 20px 0 0 0;
      }

      p {
        color: $grey-dark;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
      }
    }

    .position-indicator {
      &.previous {
        background-color: $green-light;

        &:before, &:after {
          border-left: 30px solid $green-light;
        }
      }

      position: relative;
      width: 142px;
      height: 60px;
      background-color: $purple-dark;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #ffffff;
      font-size: 16px;
      padding-left: 13px;
      font-weight: 700;
      padding-right: 30px;
      line-height: 32px;

      &:before, &:after {
        content: '';
        position: absolute;
        right: 0;
        border-left: 30px solid $purple-dark;
        width: 0;
      }

      &:before {
        top: 0;
        border-top: 30px solid $tan-dark;
      }

      &:after {
        bottom: 0;
        border-bottom: 30px solid $tan-dark;
      }
    }
  }
}

.form-title {
  color: $purple-dark;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  margin-bottom: 33px;
}

.form-subtitle {
  color: $grey-dark;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.intake-form {
  &--title {
    color: $grey-dark;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 25px;
  }

  &--content {
    * {
      color: $grey-dark;
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  &--row {
    margin-bottom: 22px;
  }

  &--column {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > span {
      position: relative;

      > span {
        position: absolute;
        bottom: -20px;
        left: 0;
      }
    }
  }

  &--input {
    /* Style for "Rectangle" */
    width: 100%;
    max-width: 400px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $grey-dark;
    background-color: #ffffff;
    box-shadow: none;
    padding: 10px 13px;

    &.is-invalid {
      border: 1px solid #bb3646;
    }
  }

  &--textbox {
    /* Style for "Rectangle" */
    width: 100%;
    border-radius: 4px;
    border: 1px solid $grey-dark;
    background-color: #ffffff;
    box-shadow: none;
    padding: 10px 13px;
    &.is-invalid {
      border: 1px solid #bb3646;
    }
  }

  &--label-large {
    color: #fffefe;
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
  }

  &--between-page {
    /* Style for "Button Bac" */
    width: 272px;
    height: 92px;
    border-radius: 4px;
    background-color: #8caf9c;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Style for "Next" */
    color: #fffefe;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    text-shadow: none;
    box-shadow: none;
    border: none;

    &.is-disabled {
      background-color: #9b9b9b;

      &:hover {
        color: #fffefe !important;
      }
    }

    &:hover {
      color: #203665;
    }
  }

  &--status {
    flex-grow: 1;
    flex-shrink: 0;
    color: #fffefe;
    font-size: 24px;
    font-weight: 700;
    height: 92px;
    display: flex;
    padding: 0 44px;
    align-items: center;
    justify-content: flex-start;

    &.is-disabled {
      background-color: #bb3646;
    }
  }
}

span.group-parent {
  display: inline-flex;
  height: 40px;
  position: static !important;

  span.radio-parent {
    margin-right: 15px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    bottom: 0;
    left: 0;

    &.has-title-values {
      width: 50px;
      height: 50px;

      [type="radio"] {
        + label > span {
          /* Style for "Y" */
          color: $grey-dark;
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          top: 13px;
          left: 18px;
        }
      }

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        width: 50px;
        height: 50px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
        width: 50px;
        height: 50px;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        width: 50px;
        height: 50px;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        width: 50px;
        height: 50px;
        background-color: #8caf9c;
      }
    }

    &.has-large-values {
      width: 100px;

      [type="radio"] {
        + label > span {
          left: 0;
          width: 100px;
          display: inline-flex;
          padding: 0 14px;
          text-align: center;
          justify-content: center;
        }
      }

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        width: 100px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
        width: 100px;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        width: 100px;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        width: 100px;
      }
    }

    &.has-tall-values {
      height: 100px;

      [type="radio"] {
        + label > span {
          padding-top: 7px !important;
        }
      }

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        height: 100px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
        height: 100px;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        height: 100px;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        height: 100px;
      }
    }

    [type="radio"] {
      &:checked {
        + label > span {
          color: white !important;
        }
      }

      &:not(:checked) {
        + label > span {

        }
      }

      + label > span {
        position: absolute;
        pointer-events: none;
        top: 8px;
        left: 14px;
        z-index: 10;
        /* Style for "M" */
        color: $grey-dark;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      cursor: pointer;
      width: 40px;
      height: 40px;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: 15;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
      position: relative;
      left: 0;
      top: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: inline-block;
      z-index: 5;
      /* Style for "Y" */
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      cursor: pointer;
      left: 0;
      top: 0;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #181818;
      background-color: #ffffff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      cursor: pointer;
      content: '';
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background: $purple-dark;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.btn-signature {
  /* Style for "Rectangle" */
  border-radius: 4px;
  border: 1px solid $grey-dark;
  background-color: white;
  /* Style for "Clear" */
  color: $grey-dark;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  padding: 11px 16px;
  text-align: center;
}

.form-saver {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 30px;
  background-color: #fff;
  z-index: 1000;
  font-size: 0.8rem;
  max-width: 300px;

  input {
    padding: 5px 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    min-width: 240px;
    font-size: 0.7rem;
  }

  a.show-hide-form-saver {
    position: absolute;
    top: 2px;
    right: 4px;
    padding: 0 4px;
  }

  &.hidden {
    bottom: unset;
    top: 100%;

    a.show-hide-form-saver {
      background-color: #fff;

      bottom: 100%;
      top: unset;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-top: 1px solid #aaa;
      border-left: 1px solid #aaa;
      border-right: 1px solid #aaa;
    }
  }
}

.form-navigation {
  text-align: right;
  width: 100%;
  margin-top: 40px;
  padding-right: 15px;
  a {
    margin-left: 10px;
  }
}
