.content-grid {
  .content-grid-rows {
    .row {
      margin-bottom: 60px;
      &:last-of-type {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
        .content-grid-content-item {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
