h1 {
  @include media-tablets {
    font-size: 7.5vw !important;
  }

  @include media-phones-landscape {
    font-size: 5vw !important;
  }
}

h2 {
  @include media-tablets {
    font-size: 6.75vw !important;
  }

  @include media-phones-landscape {
    font-size: 4.5vw !important;
  }
}

h3 {
  @include media-tablets {
    font-size: 6vw !important;
  }

  @include media-phones-landscape {
    font-size: 4vw !important;
  }
}

h4 {
  @include media-tablets {
    font-size: 5.25vw !important;
  }

  @include media-phones-landscape {
    font-size: 4vw !important;
  }
}

h5 {
  @include media-tablets-landscape {
    font-size: 2.25vw !important;
  }

  @include media-tablets {
    font-size: 5vw !important;
  }

  @include media-phones {
    font-size: 5vw !important;
  }
}

h6 {
  @include media-tablets-landscape {
    font-size: 2vw !important;
  }
  @include media-tablets {
    font-size: 3.3vw !important;
  }
}


.btn-main {
  @include button-variant(#8caf9c, #8caf9c);
  color: #fffefe;
}
